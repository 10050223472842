@use '@angular/material' as mat;
@import 'theme';

@include mat.all-component-themes($theme);
@include set-components-theme($theme);

html, body {
  height: 100%;
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media print {
  body {
    height: auto;
  }
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 8px 0;
}

p {
  margin: 0;
}

.spacer {
  flex: 1;
}

.content {
  display: flex;
  flex-direction: column;
}

.content-holder {
  height: 100%;
  overflow-y: hidden;
}

.flex-responsive-row {
  flex-direction: row;
}

@media (max-width: 768px) {
  .flex-responsive-row {
    flex-direction: column;
  }
}

a {
  cursor: pointer;
  text-decoration: underline;
}

form {
  width: 100%;

  .mat-radio-label-content {
    width: inherit;
  }
}

form > * {
  margin-bottom: 0.8em;
  width: 100%;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.fill-space {
  flex: 1 1 auto;
}

.center-align {
  text-align: center;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.center {
  margin: 0 auto;
}

.center-vertical {
  margin: auto 0;
}

.center-middle {
  margin: auto;
}

.left {
  float: left;
}

.right {
  float: right;
}

.hidden {
  display: none !important;
}

.invisible {
  display: block;
  visibility: hidden;
  height: 0;
  width: 0;
}

.transparent {
  visibility: hidden;
  opacity: 100%;
}

.mat-dialog-actions {
  justify-content: flex-end
}

.no-focus:focus {
  outline: none;
}

.multiline-tooltip {
  white-space: pre-line;
}

.word-wrap {
  word-break: break-all;
}

.word-wrap-word {
  word-break: break-word;
}

.interactive-cursor {
  cursor: pointer;
}
